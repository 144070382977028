import { createBrowserClient } from "@supabase/ssr";
import { supabaseAnonKey, supabaseUrl } from "./supabase";

function useSupabaseBrowser() {
  return createBrowserClient(supabaseUrl || "", supabaseAnonKey || "", {
    realtime: {
      params: {
        eventsPerSecond: 1
      }
    }
  });
}

export default useSupabaseBrowser;
